import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { flow, get } from "lodash/fp";
import { ThemeContext } from "styled-components";

import Head from "components/Head";
import PageHeader from "components/PageHeader";
import RichText from "components/RichText";
import Fade from "transitions/Fade";

import { Container, Row, Col } from "components/Grid";

import { LinkDecorated } from "components/Typography";

const Navigation = styled.nav`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin: 0 0 8px 0;
  }

  a {
    ${(p) => p.theme.typography.tNormal};
  }
`;

const SidebarNavigationCol = styled(Col)`
  display: none;

  ${(p) => p.theme.media.minWidth("tablet")} {
    display: block;
  }
`;

const RichTextPage = (props) => {
  const page = flow(get("data.contentful.nodes[0]"))(props);

  const theme = useContext(ThemeContext);

  useEffect(() => {
    theme.global.setHeaderPadding(false);
  }, [theme.global]);

  const seoDescription = get("seoDescription.seoDescription")(page);
  const seoImage = get("seoImage.default.images.fallback.src")(page);

  return (
    <Fade>
      <Head title={page.title} description={seoDescription} image={seoImage} />
      <PageHeader title={page.title} layout="Normal" noMargin />
      <Container>
        <Row>
          {page.sidebarNavigation && (
            <SidebarNavigationCol tb={6} lg={4} xl={5}>
              <Navigation>
                <ul>
                  {page.sidebarNavigation.pages.map((_page, i) => (
                    <li key={i}>
                      <LinkDecorated
                        className={_page.id === page.id && "is-active"}
                        to={`${_page.slug}`}
                      >
                        {_page.title}
                      </LinkDecorated>
                    </li>
                  ))}
                </ul>
              </Navigation>
            </SidebarNavigationCol>
          )}
          <Col
            tb={page.sidebarNavigation ? 18 : 20}
            tbPush={page.sidebarNavigation ? 0 : 2}
            dk={page.sidebarNavigation ? 17 : 18}
            dkPush={page.sidebarNavigation ? 0 : 3}
            lg={page.sidebarNavigation ? 16 : 16}
            lgPush={page.sidebarNavigation ? 0 : 4}
            xl={page.sidebarNavigation ? 14 : 14}
            xlPush={page.sidebarNavigation ? 0 : 5}
            xxl={page.sidebarNavigation ? 14 : 14}
            xxlPush={page.sidebarNavigation ? 0 : 5}
          >
            <RichText body={page.body} />
          </Col>
        </Row>
      </Container>
    </Fade>
  );
};

export const query = graphql`
  query RichTextPageQuery($pageId: String!) {
    contentful: allContentfulPageRichText(
      limit: 1
      filter: { id: { eq: $pageId } }
    ) {
      nodes {
        ...HeadPageRichText
        id
        title
        body {
          raw
          references {
            __typename
            ... on ContentfulAsset {
              title
              contentful_id
              gatsbyImageData
            }
          }
        }
        sidebarNavigation {
          pages {
            id
            title
            slug
          }
        }
      }
    }
  }
`;

export default RichTextPage;
